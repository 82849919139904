export const environment = {
  duuUploadBucket: 'duu-zip-upload-dev',
  identityPoolId: 'us-east-2:b347a0a1-279d-49a5-805c-8ad5413083a5',
  s3_endpoint: '',
  sts_endpoint: '',
  s3_downUrl: 'https://s3.amazonaws.com',
  appVersion: 'Version 1.0.0',
  baseUrl: 'https://rt9zsxrzod.execute-api.us-east-2.amazonaws.com/dev',
  baseUrlClp: 'https://rt9zsxrzod.execute-api.us-east-2.amazonaws.com/dev',
  baseUrlClpSnn: 'https://8aoubljmgj.execute-api.us-east-2.amazonaws.com/dev',
  baseUnauthUrl: 'https://boaafiivf1.execute-api.us-east-1.amazonaws.com/test',
  userPoolAwsLogin: 'cognito-idp.us-east-2.amazonaws.com/us-east-2_vYWAv7vxN',
  production: true,
  lensSenderEmail: 'snnoreply@maildrop.cc',
  destinationBucket: 'cup-dev-lumino',
  region: 'us-east-2',
  apiVersionCognito: '2016-04-18',
  apiVersionSqs: '2012-11-05',
  lambdaFunction: {
    bridgeDuuProcessZipUpload: 'bridge-duu-process-zip-upload-dev',
    lambdaRegion: 'us-east-2',
  },
  ssmParams: {
    IDLETIME : "/duu-dev/image-upload-service_dev/inactivityTime_login",
    DIACOMTAG : "/duu-dev/image-upload-service_dev/validDicomTags",
    RECALLTIME : "/duu-dev/image-upload-service_dev/recallTime",
    AUDITINTERVALTIME : "/duu-dev/image-upload-service_dev/auditInterval",
    APPVERSION: "/duu-dev/image-upload-service_dev/appVersionInfo",
    APPDIACOMTAGSCRIPT: "/duu-dev/image-upload-service_dev/validApplicationDicomTags",
    SQSQUEUEURL: "/duu-dev/DUU_Group_by_patient/group-by-patient-queue-url-dev"
  },

  //ALL ROLES EXPECT SNN
  clp: {
    hostedUIUrl: 'https://bridge-dev-us-east-2.auth.us-east-2.amazoncognito.com/oauth2/',
    hostedUIlogout: 'https://bridge-dev-us-east-2.auth.us-east-2.amazoncognito.com/logout',
    identityPoolId: 'us-east-2:b347a0a1-279d-49a5-805c-8ad5413083a5',
    userPoolId: 'us-east-2_vYWAv7vxN',
    userPoolWebClientId: '76302lejp285cslqbic3hjqfrh',
    userPoolAwsLogin: 'cognito-idp.us-east-2.amazonaws.com/us-east-2_vYWAv7vxN',
	  cloudfrontCLPURL: 'https://bridge-dev.faiplan-dev.com',
    cloudFrontCLPSignInUrl: 'https://bridge-dev.faiplan-dev.com/signin',
    cloudFrontCLPSignOutUrl: 'https://bridge-dev.faiplan-dev.com/logout',
    cloudFrontCLPExitAppUrl: 'https://bridge-dev.faiplan-dev.com/application/product',
    // cloudfrontCLPURL: 'http://localhost:4201',
    // cloudFrontCLPSignInUrl: 'http://localhost:4201/signin',
    // cloudFrontCLPSignOutUrl: 'http://localhost:4201/logout',
    // cloudFrontCLPExitAppUrl: 'http://localhost:4201/application/product',
  },
  //SNN ROLE
  snnClp: {
    hostedUIUrl: 'https://bridge-snn-us-east-2.auth.us-east-2.amazoncognito.com/oauth2/',
    hostedUIlogout: 'https://bridge-snn-us-east-2.auth.us-east-2.amazoncognito.com/logout',
    identityPoolId: 'us-east-2:577a6622-fd98-4dc1-b2a4-24c68bba671c',
    userPoolId: 'us-east-2_JZephE5Dc',
    userPoolWebClientId: '7rbe6jnk9lk4pm8lv3os41c20c',
    userPoolAwsLogin: 'cognito-idp.us-east-2.amazonaws.com/us-east-2_JZephE5Dc',
    cloudfrontCLPURL: 'https://bridge-snn-dev.faiplan-dev.com',
    cloudFrontCLPSignInUrl: 'https://bridge-snn-dev.faiplan-dev.com/signin',
    cloudFrontCLPSignOutUrl: 'https://bridge-snn-dev.faiplan-dev.com/logout',
    cloudFrontCLPExitAppUrl: 'https://bridge-snn-dev.faiplan-dev.com/application/product',
  },
  duuCloudFrontSigninUrl: 'https://datauploadutility-dev.aetsamd-dev.com/signin',
  // duuCloudFrontSigninUrl: 'http://localhost:4200/signin',
};
